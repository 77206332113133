<template>
  <div class="organization">
    <div class="page-header">
      <h2>我的组织</h2>
    </div>
    <el-row>
      <el-col :span="6">
        <el-tree v-loading="treeLoading" :data="processedDepartments" :props="defaultProps" :default-expand-all="false"
          node-key="id" @node-click="handleNodeClick" class="department-tree" ref="tree">
          <span class="custom-tree-node" slot-scope="{ node }">
            <span>{{ node.label }}</span>
          </span>
        </el-tree>
      </el-col>
      <el-col :span="18">
        <!-- 添加面包屑导航 -->
        <el-breadcrumb separator=">" class="breadcrumb">
          <el-breadcrumb-item v-for="(item, index) in breadcrumbItems" :key="index">
            {{ item.name }}
            <template v-if="index === breadcrumbItems.length - 1">
              ({{ departmentMembers.length }}人)
            </template>
          </el-breadcrumb-item>
        </el-breadcrumb>
        <el-table v-loading="tableLoading" :data="departmentMembers" v-if="selectedDepartment">
          <el-table-column prop="username" label="姓名"></el-table-column>
          <el-table-column prop="userid" label="人员编号"></el-table-column>
          <el-table-column prop="mail" label="邮箱">
            <template slot-scope="scope">
              {{ scope.row.mail || '-' }}
            </template>
          </el-table-column>
          <el-table-column label="角色" align="center" min-width="120">
            <template slot-scope="{row}">
              <el-tag v-if="row.role_name" type="success">{{ row.role_name }}</el-tag>
              <span v-else>未分配</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="120">
            <template slot-scope="{row}">
              <el-button type="primary" size="mini" @click="handleSetRole(row)">
                设置角色
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <!-- 设置用户角色对话框 -->
    <el-dialog title="设置用户角色" :visible.sync="roleDialogVisible" width="400px">
      <el-form :model="roleForm" label-width="100px">
        <el-form-item label="用户名">
          <span>{{ currentUser ? currentUser.username : '' }}</span>
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="roleForm.role_id" placeholder="请选择角色" style="width: 100%">
            <el-option v-for="item in roleOptions" :key="item.id" :label="item.role_name" :value="item.id" />
            <el-option :value="null" label="无角色" />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="roleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveUserRole" :loading="saveLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getDepartments, getDepartmentMembers, getRoles, setUserRole } from '@/api'

export default {
  name: 'Organization',
  data() {
    return {
      departments: [],
      departmentMembers: [],
      selectedDepartment: null,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      breadcrumbItems: [],
      treeLoading: false,
      tableLoading: false,
      // 角色相关数据
      roleDialogVisible: false,
      roleForm: {
        role_id: null
      },
      currentUser: null,
      roleOptions: [],
      saveLoading: false
    };
  },
  computed: {
    processedDepartments() {
      return this.departments;
    }
  },
  created() {
    this.fetchDepartments();
    this.fetchRoles();
  },
  methods: {
    async fetchDepartments() {
      this.treeLoading = true;
      try {
        const response = await getDepartments();
        if (response.code === 200) {
          this.departments = response.data;
          // 选中第一个节点
          this.$nextTick(() => {
            if (this.departments.length > 0) {
              const firstNode = this.departments[0];
              this.$refs.tree.setCurrentKey(firstNode.id);
              this.handleNodeClick(firstNode);
            }
          });
        }
      } catch (error) {
        console.error('获取组织架构失败:', error);
        this.$message.error('获取组织架构失败');
      } finally {
        this.treeLoading = false;
      }
    },
    async handleNodeClick(data) {
      this.selectedDepartment = data;
      // 更新面包屑
      this.updateBreadcrumb(data);

      this.tableLoading = true;
      try {
        const response = await getDepartmentMembers({ departmentId: data.id });
        if (response.code === 200) {
          // 处理返回的用户数据，提取角色信息
          this.departmentMembers = response.data.map(user => {
            return {
              ...user,
              role_id: user.role?.role_id || null,
              role_name: user.role?.role_name || null
            };
          });
        }
      } catch (error) {
        console.error('获取部门成员失败:', error);
        this.$message.error('获取部门成员失败');
      } finally {
        this.tableLoading = false;
      }
    },
    updateBreadcrumb(node) {
      if (!node) return;
      const path = this.findNodePath(this.processedDepartments[0], node.id);
      this.breadcrumbItems = path || [node];
    },
    findNodePath(root, targetId, path = []) {
      if (!root) return null;

      path.push(root);
      if (root.id === targetId) return path;

      if (root.children) {
        for (const child of root.children) {
          const found = this.findNodePath(child, targetId, [...path]);
          if (found) return found;
        }
      }

      return null;
    },
    // 角色相关方法
    async fetchRoles() {
      try {
        const res = await getRoles({ page: 1, page_size: 100 });
        if (res.code === 200) {
          this.roleOptions = res.data.roles;
        }
      } catch (error) {
        console.error('获取角色列表失败:', error);
        this.$message.error('获取角色列表失败');
      }
    },
    handleSetRole(row) {
      this.currentUser = row;
      this.roleForm.role_id = row.role_id;
      this.roleDialogVisible = true;
    },
    async saveUserRole() {
      if (!this.currentUser) return;
      
      this.saveLoading = true;
      try {
        // 使用userid而不是id
        const userId = this.currentUser.userid || this.currentUser.id;
        const res = await setUserRole(userId, { role_id: this.roleForm.role_id });
        if (res.code === 200) {
          this.$message.success('用户角色设置成功');
          this.roleDialogVisible = false;
          
          // 更新当前用户的角色信息
          if (this.roleForm.role_id === null) {
            this.currentUser.role_id = null;
            this.currentUser.role_name = null;
          } else {
            this.currentUser.role_id = this.roleForm.role_id;
            const role = this.roleOptions.find(r => r.id === this.roleForm.role_id);
            this.currentUser.role_name = role ? role.role_name : '';
          }
          
          // 刷新部门成员列表
          this.handleNodeClick(this.selectedDepartment);
        }
      } catch (error) {
        console.error('设置用户角色失败:', error);
        this.$message.error('设置用户角色失败');
      } finally {
        this.saveLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.organization {
  padding: 20px;
}

.page-header {
  margin-bottom: 20px;
}

.breadcrumb {
  margin: 20px 20px 20px;
  padding: 10px 0;
}

.department-tree {
  padding: 20px;
  border-right: 1px solid #EBEEF5;
  height: calc(100vh - 180px);
  /* 调整高度以适应面包屑 */
  overflow-y: auto;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding-right: 8px;
}

:deep(.el-tree-node__content) {
  height: 32px;
}

:deep(.el-tree-node.is-current > .el-tree-node__content) {
  background-color: #f5f7fa;
  color: #f23e31;
}

/* 添加表格容器样式 */
.el-col:nth-child(2) {
  padding: 0 20px;
}

:deep(.el-table__row) {
  height: 32px;
}
</style>